<template>
  <div class="admin-container">
    <confirmation-modal
      :show-modal="confirmationModal.active"
      :loading="confirmationModal.loading"
      element="receipt(s)"
      @yes="deleteReceipts"
      @no="setResetConfirmationModal"
    />
    <v-overlay v-model="dataLoading" z-index="50">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-dialog
      v-model="showModal"
      persistent
      max-width="590"
      width="fit-content"
    >
      <v-card>
        <v-toolbar color="text-h5 primary">Receipt Image</v-toolbar>
        <v-card-text>
          <div class="pa-12">
            <v-img :src="`data:image/*;base64,${receiptImage}`" />
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn variant="text" @click="closeImageDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="12">
          <v-card>
            <v-tabs v-model="selectedTab">
              <v-tab>
                <i class="fa fa-address-card mr-2"></i>
                Receipt listing
              </v-tab>
              <v-tab @click="fetchRegister">
                <i class="fa fa-user mr-2"></i>
                Registration listing
              </v-tab>

              <v-tab-item key="receiptList">
                <div class="table-header mb-5">
                  <v-tooltip
                    bottom
                    class="mr-2"
                    v-if="selectedReceipts.length !== 0"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        type="button"
                        color="primary"
                        dark
                        large
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          setResetConfirmationModal(true, selectedReceipts)
                        "
                      >
                        <i class="fas fa-trash"></i>
                      </v-btn>
                    </template>
                    <span>Delete Selected Receipt(s)</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    class="mr-2"
                    v-if="selectedReceipts.length !== 0"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        type="button"
                        color="primary"
                        dark
                        large
                        v-bind="attrs"
                        v-on="on"
                        @click="downloadReceipts"
                      >
                        <i class="fas fa-download"></i>
                      </v-btn>
                    </template>
                    <span>Download Selected Receipt(s)</span>
                  </v-tooltip>
                  <v-tooltip bottom class="mr-2">
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        type="button"
                        color="primary"
                        dark
                        large
                        v-bind="attrs"
                        v-on="on"
                        :disabled="isExcelLoading"
                        :loading="isExcelLoading"
                        @click="download('Excel')"
                      >
                        <i class="fas fa-file-excel"></i>
                      </v-btn>
                    </template>
                    <span>Excel Sheet Download</span>
                  </v-tooltip>
                  <v-tooltip bottom class="mr-2">
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        type="button"
                        color="primary"
                        dark
                        large
                        v-bind="attrs"
                        v-on="on"
                        @click="download('Pdf')"
                        :disabled="isPdfLoading"
                        :loading="isPdfLoading"
                      >
                        <i class="fas fa-file-pdf"></i>
                      </v-btn>
                    </template>
                    <span>PDF Download</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        type="button"
                        color="primary"
                        dark
                        large
                        v-bind="attrs"
                        v-on="on"
                        @click="isActive = !isActive"
                      >
                        <i class="fa fa-filter"></i>
                      </v-btn>
                    </template>
                    Filter menu
                  </v-tooltip>
                  <div
                    :style="{ display: isActive ? 'block' : 'none' }"
                    class="filterOverlay"
                    @click="isActive = false"
                  ></div>
                  <v-row
                    class="responsive-header align-md-center"
                    ref="menuHeader"
                    :class="{ active: isActive }"
                  >
                    <a
                      href="javascript:void(0);"
                      @click="isActive = !isActive"
                      class="text-h5 mr-5 black--text"
                    >
                      <i class="fa fa-times"></i>
                    </a>
                    <v-col cols="12">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="receiptDate"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        hide-details
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="receiptDate"
                            label="Select Receipt Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="receiptDate"
                          no-title
                          scrollable
                          :max="new Date().toISOString()"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(receiptDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="eventId"
                        :items="events"
                        class="form-control"
                        placeholder="Select Event"
                        item-value="id"
                        item-text="eventName"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                        hide-details
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="categoryId"
                        :items="categories"
                        class="form-control"
                        placeholder="Select Category"
                        item-value="id"
                        item-text="categoryName"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                        hide-details
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="cardId"
                        :items="cards"
                        class="form-control"
                        placeholder="Select Card"
                        item-value="id"
                        item-text="cardTypeName"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                        hide-details
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="amount"
                        class="form-control"
                        placeholder="Amount"
                        required
                        outlined
                        dark
                        hide-details
                        background-color="#f0f1f6"
                        color="#000"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="last4cc"
                        class="form-control"
                        placeholder="CC"
                        required
                        outlined
                        dark
                        hide-details
                        background-color="#f0f1f6"
                        color="#000"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="addedByUserId"
                        :items="users"
                        class="form-control"
                        placeholder="Select Added By"
                        item-value="userId"
                        item-text="name"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                        hide-details
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-tooltip bottom class="mr-2">
                        <template #activator="{ on, attrs }">
                          <v-btn
                            type="button"
                            color="accent"
                            dark
                            large
                            style="width: 100%; max-width: 100%"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="disabledSearching"
                            class="adminpageButtons"
                            @click="resetfilter(), (selectedReceipts = [])"
                          >
                            <i class="fa fa-sync"></i>
                          </v-btn>
                        </template>
                        <span>Reset Filter</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12">
                      <v-tooltip bottom class="mr-2">
                        <template #activator="{ on, attrs }">
                          <v-btn
                            type="submit"
                            color="accent"
                            large
                            dark
                            style="width: 100%; max-width: 100%"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="disabledSearching"
                            class="adminpageButtons"
                            @click="searchReceipt(), (selectedReceipts = [])"
                          >
                            <i class="fa fa-search"></i>
                          </v-btn>
                        </template>
                        <span>Search Receipts</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
                <v-data-table
                  show-select
                  v-model="selectedReceipts"
                  :headers="receiptHeader"
                  :items="receiptsList"
                  :loading="receiptLoading"
                  class="elevation-1"
                  :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50],
                  }"
                  :server-items-length="totalItems"
                  :options.sync="options"
                  @update:options="fetchReceipts"
                  @update:items-per-page="selectedReceipts = []"
                  @update:page="selectedReceipts = []"
                >
                  <template v-slot:[`header.name`]="{ header }">
                    {{ header.text.toUpperCase() }}
                  </template>
                  <template v-slot:[`item.imgByte`]="{ item }">
                    <v-tooltip v-if="isNotPdf(item.imageName)" bottom>
                      <template #activator="{ on, attrs }">
                        <v-icon
                          size="xx-large"
                          style="width: 50px; height: 50px"
                          v-bind="attrs"
                          v-on="on"
                          color="primary"
                          @click="openPreviewModal(item.id)"
                          >fa fa-file-image</v-icon
                        >
                      </template>
                      Preview Receipt
                    </v-tooltip>

                    <v-tooltip bottom v-else>
                      <template #activator="{ on, attrs }">
                        <v-icon
                          class="ml-3"
                          size="30"
                          v-bind="attrs"
                          v-on="on"
                          color="#ff0000"
                          >fas fa-file-pdf</v-icon
                        >
                      </template>
                      PDF File
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.receiptDate`]="{ item }">
                    {{ item.receiptDate.substr(0, 10) }}
                  </template>
                  <template v-slot:[`item.cardTypeName`]="{ item }">
                    {{ item.cardTypeName }}
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-menu
                      offset-y
                      max-width="250"
                      max-height="300"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                              icon
                              text
                              :disabled="item.notes === null"
                              v-bind="attrs"
                              v-on="{ ...tooltip, ...menu }"
                            >
                              <v-icon color="primary">fa fa-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>View Notes</span>
                        </v-tooltip>
                      </template>
                      <div class="white pa-5" style="word-wrap: break-word">
                        <div class="font-weight-bold">NOTES:</div>
                        {{ item.notes }}
                      </div>
                    </v-menu>

                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          v-bind="attrs"
                          v-on="on"
                          :to="`/receipt/edit/${item.id}`"
                        >
                          <v-icon color="primary">fa fa-edit</v-icon></v-btn
                        >
                      </template>
                      Edit Receipt
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          :disabled="item.imageName === null"
                          @click="downloadReceipt(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="primary">fa fa-download</v-icon></v-btn
                        >
                      </template>
                      Download Receipt
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          v-bind="attrs"
                          v-on="on"
                          @click="setResetConfirmationModal(true, [item])"
                        >
                          <v-icon color="primary" class="fas fa-trash"></v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Receipt</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item key="registerList">
                <div class="table-header text-right mb-4">
                  <v-btn class="ml-sm-4" color="primary" to="/register">
                    Add User <i class="fa fa-plus ml-1"></i>
                  </v-btn>
                </div>
                <v-data-table
                  item-key="userId"
                  :headers="registerHeader"
                  :items="registrationList"
                  class="elevation-1 registrationList"
                  :loading="registrationLoading"
                  must-sort
                >
                  <template v-slot:[`header.name`]="{ header }">
                    {{ header.text.toUpperCase() }}
                  </template>
                  <template v-slot:[`item.phone`]="{ item }">
                    {{
                      item.phone
                        .replace(/\D+/g, "")
                        .replace(
                          /([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi,
                          "$1-$2-$3"
                        )
                    }}
                  </template>

                  <template v-slot:[`item.active`]="{ item }">
                    {{ item.active ? "Yes" : "No" }}
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          :disabled="item.imageName === null"
                          v-bind="attrs"
                          v-on="on"
                          :to="`/user/edit/${item.userId}`"
                        >
                          <v-icon color="primary">fa fa-edit</v-icon></v-btn
                        >
                      </template>
                      Edit User
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReceiptService from "@/services/ReceiptService";
import UserService from "@/services/UserService";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
export default {
  components: {
    ConfirmationModal,
  },
  data: () => ({
    isActive: false,
    registerHeader: [
      { text: "First Name", value: "firstName", width: 150 },
      { text: "Last Name", value: "lastName", width: 150 },
      { text: "Email", value: "email", width: 100 },
      { text: "Is Active", value: "active", width: 120 },
      { text: "Address", value: "address", width: 100 },
      { text: "Phone", value: "phone", width: 100 },
      { text: "Role", value: "roleName", width: 100 },
      { text: "Actions", value: "action", width: 100, sortable: false },
    ],
    registrationList: [],
    receiptHeader: [
      { text: "Receipt Image", value: "imgByte", width: 150, sortable: false },
      { text: "Category", value: "categoryName", width: 120 },
      { text: "Event", value: "eventName", width: 100 },
      { text: "Amount", value: "amount", width: 120 },
      { text: "CC 4 Digit", value: "last4DigitofCC", width: 130 },
      { text: "Card Type", value: "cardTypeName", width: 130 },
      { text: "Receipt Date", value: "receiptDate", width: 150 },
      { text: "Added By", value: "addedBy", width: 140 },
      { text: "Actions", value: "action", width: 200, sortable: false },
    ],
    receiptsList: [],
    receiptLoading: false,
    registrationLoading: false,
    receiptDate: "",
    categoryId: "",
    eventId: "",
    addedByUserId: "",
    last4cc: "",
    amount: "",
    cardId: "",
    menu: false,
    totalItems: null,
    isExcelLoading: false,
    isPdfLoading: false,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
    },
    selectedReceipts: [],
    selectedTab: 0,
    showNotesIndex: null,
    confirmationModal: {
      active: false,
      loading: false,
      dataList: [],
    },
    showModal: false,
    dataLoading: false,
    receiptImage: "",
  }),
  async created() {
    if (this.categories.length === 0 || this.events.length === 0) {
      await this.$store.dispatch("receipt/FETCH_COMMON_DATA");
    }
    if (this.cards.length === 0) {
      await this.$store.dispatch("receipt/FETCH_CARDS");
    }
  },
  mounted() {
    if (this.$store.state.isUserEddited === true) {
      this.selectedTab = 1;
      this.fetchRegister();
      this.$store.dispatch("SET_USER_EDDITED", false);
    }
  },
  computed: {
    ...mapState({
      categories: (state) => {
        return state.receipt.categories.filter((category) => category.active);
      },
      events: (state) => {
        return state.receipt.events.filter((event) => event.active);
      },
      users: (state) => {
        let userArr1 = [];
        let userArr = state.receipt.users.filter((user) => user.active);
        userArr.map(function (value) {
          let fullName = (value.firstName || "") + " " + (value.lastName || "");
          userArr1.push({ userId: value.userId, name: fullName });
        });
        return userArr1?.sort((a, b) =>
          a.name.localeCompare(b.name, "en", { numeric: true })
        );
      },
      cards: (state) => state.receipt.cards.filter((card) => card.active),
    }),
    disabledSearching() {
      return (
        this.receiptDate === "" &&
        this.categoryId === "" &&
        this.eventId === "" &&
        this.addedByUserId === "" &&
        this.last4cc === "" &&
        this.amount === "" &&
        this.cardId === ""
      );
    },
  },
  methods: {
    showNotes(index) {
      this.showNotesIndex = index;
    },
    async fetchReceipts() {
      this.isActive = false;
      this.receiptLoading = true;
      let sortBy = null;
      if (this.sortBy !== null) {
        switch (this.options.sortBy[0]) {
          case "categoryName":
            sortBy = "Category";
            break;
          case "eventName":
            sortBy = "Event";
            break;
          case "amount":
            sortBy = "Amount";
            break;
          case "last4DigitofCC":
            sortBy = "Last4DigitofCC";
            break;
          case "receiptDate":
            sortBy = "ReceiptDate";
            break;
          case "addedBy":
            sortBy = "AddedBy";
            break;
          case "cardTypeName":
            sortBy = "CardTypeName";
            break;
        }
      }
      await ReceiptService.fetchReceipts(
        this.options.page,
        this.options.itemsPerPage,
        sortBy || null,
        this.options.sortDesc[0] !== undefined
          ? this.options.sortDesc[0]
          : null,
        this.receiptDate,
        this.categoryId,
        this.eventId,
        this.amount,
        this.last4cc,
        this.cardId,
        this.addedByUserId
      )
        .then((res) => {
          this.receiptLoading = false;
          this.receiptsList = res.data.data.receiptList;
          this.totalItems = res.data.data.pagination.total;
        })
        .catch((error) => {
          this.receiptLoading = false;
          this.$store.dispatch("notifications/setNotification", {
            type: "error",
            text: error.response.data.errorMessage,
            status: error.response.status,
          });
        });
    },
    fetchRegister() {
      if (this.registrationList.length === 0) {
        this.fetchRegistrationList();
      }
    },
    fetchRegistrationList() {
      this.registrationLoading = true;
      UserService.getRegisteredList()
        .then((res) => {
          this.registrationLoading = false;
          this.registrationList = res.data.data;
        })
        .catch((error) => {
          this.registrationLoading = false;
          this.$store.dispatch("notifications/setNotification", {
            type: "error",
            text: error.response.data.errorMessage,
            status: error.response.status,
          });
        });
    },
    searchReceipt() {
      this.options = {
        page: 1,
        itemsPerPage: this.options.itemsPerPage,
        sortBy: [],
        sortDesc: [],
      };
      this.fetchReceipts();
    },
    async downloadReceipt(item) {
      this.dataLoading = true;
      let image;
      const res = await ReceiptService.fetchReceiptById(item.id);
      if (res.data.errorMessage === null) {
        image = res.data.data.imgByte;
      }
      return new Promise((resolve) => {
        setTimeout(() => {
          const linkSource = `data:image/*;base64,${image}`;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = `${item.imageName}`;
          downloadLink.click();
          downloadLink.remove();
          this.dataLoading = false;
          return resolve();
        }, 500);
      });
    },
    resetfilter() {
      this.receiptDate = "";
      this.categoryId = "";
      this.eventId = "";
      this.addedByUserId = "";
      this.last4cc = "";
      this.amount = "";
      this.cardId = "";
      this.options = {
        page: 1,
        itemsPerPage: this.options.itemsPerPage,
        sortBy: [],
        sortDesc: [],
      };
      this.fetchReceipts();
    },
    async download(type) {
      if (type === "Excel") {
        this.isExcelLoading = true;
      } else {
        this.isPdfLoading = true;
      }
      await ReceiptService.exportExcel(
        type,
        this.receiptDate,
        this.categoryId,
        this.eventId,
        this.amount,
        this.last4cc,
        this.cardId,
        this.addedByUserId,
        this.options.sortBy,
        this.options.sortDesc,
        this.totalItems
      )
        .then((response) => {
          let filename = response.headers["content-disposition"]
            ?.split(";")
            ?.find((n) => n.includes("filename="))
            ?.replace("filename=", "")
            ?.trim();
          if (!filename) {
            filename = `SRLife-${new Date().toISOString()}`;
          }
          filename = type === "Excel" ? `${filename}.xlsx` : `${filename}.pdf`;
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          this.isExcelLoading = false;
          this.isPdfLoading = false;
        })
        .catch((error) => {
          this.isExcelLoading = false;
          this.isPdfLoading = false;
          this.$store.dispatch("notifications/setNotification", {
            type: "error",
            text: error.response.data.errorMessage,
            status: error.response.status,
          });
        });
    },
    async downloadReceipts() {
      for (const receipt of this.selectedReceipts) {
        await this.downloadReceipt(receipt);
      }
    },
    isNotPdf(name) {
      return name.split(".")[1] !== "pdf";
    },
    setResetConfirmationModal(active = false, data = [], loading = false) {
      this.confirmationModal = {
        active,
        loading,
        data,
      };
    },
    async deleteReceipts() {
      try {
        this.confirmationModal.loading = true;
        const deleteReceiptIds = this.confirmationModal.data.map(
          (item) => item.id
        );
        const res = await ReceiptService.deleteReceipts(deleteReceiptIds);
        if (res.data.errorMessage) {
          throw res;
        } else {
          await this.fetchReceipts();
          this.selectedReceipts = [];
          this.$store.dispatch("notifications/setNotification", {
            type: "success",
            text: "Receipt(s) Deleted Successfully.",
            status: res.data.status,
          });
          this.setResetConfirmationModal();
        }
      } catch (error) {
        this.setResetConfirmationModal();
        this.$store.dispatch("notifications/setNotification", {
          type: "error",
          text:
            error?.data?.errorMessage || error?.response?.data?.errorMessage,
          status: error?.data?.status || error?.response?.status,
        });
      }
    },
    async openPreviewModal(id) {
      try {
        this.dataLoading = true;
        const res = await ReceiptService.fetchReceiptById(id);
        if (res.data.errorMessage === null) {
          this.receiptImage = res.data.data.imgByte;
          this.showModal = true;
        } else {
          this.$store.dispatch("notifications/setNotification", {
            type: "error",
            text: res.data.errorMessage,
          });
        }
        this.dataLoading = false;
      } catch (error) {
        this.dataLoading = false;
        this.$store.dispatch("notifications/setNotification", {
          type: "error",
          text: error.response.data.errorMessage,
          status: error.response.status,
        });
      }
    },
    closeImageDialog() {
      this.showModal = false;
      this.dataLoading = false;
    },
  },
};
</script>
