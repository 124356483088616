<template>
  <v-dialog :value="showModal" persistent max-width="590" width="fit-content">
    <v-card>
      <v-card-title class="headline">
        <slot name="title"
          >Are you sure you want to {{ modalType }} the {{ element }}?</slot
        >
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="loading"
          :loading="loading"
          color="primary"
          @click="$emit('yes')"
        >
          <slot name="yes"> Yes </slot>
        </v-btn>
        <v-btn color="dark" dark @click="$emit('no')">
          <slot name="no"> No </slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    element: {
      type: String,
      default: "Element",
    },
    modalType: {
      type: String,
      default: "delete",
    },
  },
};
</script>
